import api from "../httpClient.js";

export async function checkOnu() {
  const response = await api.get("/smartolt/check-onu");
  return response.data.onus;
}

export async function onuTypes() {
  const response = await api.get("/smartolt/onu-types");
  return response.data.onu_types;
}

export function provisionarOnu(dados) {
  let body = {};
  for (let key in dados) {
    body[key] = dados[key];
  }
  return api.post("/smartolt/provisionar-onu", body);
}

export async function desprovisionarOnu(id) {
  const response = await api.post(`smartolt/desprovisionar-onu/${id}`);
  return response.data;
}
