<template>
  <!-- style="height: 351px; max-height: 351px;" -->
  <v-card-text>
    <v-dialog v-model="dialogTarefa" width="100%" max-width="400px">
      <v-card :loading="loadingTarefa" :disabled="loadingTarefa">
        <v-card-title class="subtitle-1 lightgray">
          Adicionar Tarefa
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTarefa = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- Descrição -->
          <v-text-field
            v-model="tarefa.descricao"
            label="Descrição"
            class="mt-4"
            filled
            hide-details
            dense
          ></v-text-field>

          <!-- Tipo -->
          <v-select
            v-model="tarefa.tipo"
            :items="tiposTarefa"
            class="mt-4"
            clearable
            item-text="text"
            item-value="value"
            dense
            filled
            hide-details
            label="Tipo"
          ></v-select>

          <!-- Obrigatorio -->
          <v-card
            flat
            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
            height="56px"
            class="pa-3 mt-4 d-flex justify-space-between align-center"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
            >
              Obrigatorio
            </v-subheader>

            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="false"
              :true-value="true"
              v-model="tarefa.obrigatorio"
            ></v-switch>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="toolbar"
            dark
            depressed
            small
            :disabled="disableTarefa"
            @click="createTarefa"
          >
            Criar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="tarefas.length && !loading">
      <div
        class="mb-6 pt-6 d-flex align-center justify-end"
        v-if="
          original.status != 3 && original.status != 4 && original.status != -1
        "
      >
        <v-btn
          depressed
          color="toolbar"
          small
          dark
          @click="dialogTarefa = true"
        >
          Tarefa
          <v-icon right>add</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-if="!tarefas.length && !loading">
      <div
        class="pa-6 fill-height d-flex flex-column align-center justify-center"
      >
        <v-btn
          v-if="
            original.status != 3 &&
              original.status != 4 &&
              original.status != -1
          "
          depressed
          color="toolbar"
          large
          dark
          @click="dialogTarefa = true"
        >
          Tarefa
          <v-icon right>add</v-icon>
        </v-btn>
        <div class="mb-6 headline text-center">Nenhuma tarefa encontrada!</div>
      </div>
    </template>

    <template v-if="!loading">
      <v-card outlined v-if="tarefaProvisionamento" class="mt-6">
        <v-card-title class="body-2 lightgray py-2 px-3">
          Provisionamento de ONU
          <v-spacer></v-spacer>
          <v-tooltip left color="error">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                size="21"
                class="ml-1"
                color="error"
                v-if="tarefaProvisionamento.obrigatorio"
              >
                error
              </v-icon>
            </template>
            <span class="caption">Obrigatório</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <template v-if="tarefaProvisionamento.valor">
          <v-card-text>
            Provisionamento efetuado em:
            <div class="mt-2">
              Serial
              <v-sheet class="pa-2" color="lightgray">
                {{ tarefaProvisionamento.valor }}
              </v-sheet>
            </div>
            <v-btn
              class="mt-6"
              small
              color="primary"
              @click="excluirProvisionamento"
            >
              Excluir Provisionamento
            </v-btn>
          </v-card-text>
        </template>
        <template v-else>
          <template v-if="!provisionando">
            <!-- Validar ONU -->
            <v-card-text class="pb-0">
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="serial"
                      outlined
                      dense
                      label="Serial da ONU"
                      :error="onuValida === false"
                      :error-messages="
                        onuValida === false
                          ? 'Onu não esta pendente ou nao foi encontrada'
                          : ''
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn
                      :disabled="serial ? false : true"
                      depressed
                      block
                      height="40px"
                      :class="{
                        primary: onuValida === null,
                        success: onuValida,
                        error: onuValida === false,
                      }"
                      :loading="validandoOnu"
                      @click="verificaOnu"
                    >
                      <template v-if="onuValida === null">
                        <span class="white--text">Validar</span>
                      </template>
                      <template v-if="onuValida === false">
                        <v-icon class="mr-2">error</v-icon>
                        <span class="white--text">Validar</span>
                      </template>
                      <template v-if="onuValida">
                        <v-icon class="mr-2">check</v-icon>
                        <span class="white--text">Valido</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </template>
          <Carregando v-else />
        </template>
      </v-card>

      <v-card outlined v-if="tarefaChecklist.length">
        <v-card-title class="body-2 lightgray py-2 px-3">
          Checklist
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <v-list flat nav>
            <v-list-item
              v-for="(tarefa, index) in tarefaChecklist"
              :key="index"
              style="max-height: 40px;"
              class="lightgray"
            >
              <v-list-item-action>
                <v-checkbox
                  v-model="tarefa.valor"
                  true-value="1"
                  false-value="0"
                  color="primary"
                  @click.stop="toogleChecklist(tarefa)"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title
                  class="body-2 mb-0"
                  v-show="editOffset != index"
                >
                  {{ tarefa.descricao }}
                </v-list-item-title>
                <v-list-item-title v-show="editOffset == index">
                  <!-- Descrição -->
                  <v-text-field
                    v-model="tarefa.descricao"
                    hide-details
                    dense
                    @blur="editOffset = -1"
                    :append-icon="editOffset == index ? 'save' : ''"
                    @click:append.stop="updateCheckListItem(index)"
                  ></v-text-field>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-show="editOffset != index">
                <v-btn icon small @click.prevent="editCheckListItem(index)">
                  <v-icon small>edit</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-card flat class="mt-6 " v-if="tarefaText.length">
        <v-card-text class="pa-0">
          <v-card
            class="mb-3"
            v-for="(tarefa, index) in tarefaText"
            :key="index"
            outlined
          >
            <v-card-title class="body-2 lightgray py-2 px-3">
              {{ index + 1 }}. {{ tarefa.descricao }}
              <v-spacer></v-spacer>
              <v-tooltip left color="error">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="tarefa.obrigatorio"
                    v-on="on"
                    size="21"
                    class="ml-1"
                    color="error"
                  >
                    error
                  </v-icon>
                </template>
                <span class="caption">Obrigatório</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-3">
              <v-textarea
                v-model="tarefa.valor"
                placeholder="Escreva sua resposta..."
                @click:append.stop="updateTextItem(index)"
                @focus="tarefa.focus = true"
                @blur="tarefa.focus = false"
                :append-icon="tarefa.focus ? 'save' : ''"
                :rows="1"
                auto-grow
                hide-details
                dense
                class="caption"
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card flat class="mt-6 " v-if="tarefaImagem.length">
        <v-card-text class="pa-0">
          <v-card
            class="mb-3"
            v-for="(tarefa, index) in tarefaImagem"
            :key="index"
            outlined
          >
            <v-card-title class="body-2 lightgray py-2 px-3">
              {{ index + 1 }}. {{ tarefa.descricao }}
              <v-spacer></v-spacer>
              <v-tooltip left color="error">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="tarefa.obrigatorio"
                    v-on="on"
                    size="21"
                    class="ml-1"
                    color="error"
                  >
                    error
                  </v-icon>
                </template>
                <span class="caption">Obrigatório</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <SelectImage
                :save="false"
                origem="eventos_tarefas"
                :origem_id="tarefa.id"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </template>
    <Carregando v-else />
  </v-card-text>
</template>

<script>
import {
  getEventoTarefas,
  putEventoTarefa,
  postEventoTarefa,
} from "@/services/api/eventos-tarefas.api.js";

import { mapState } from "vuex";
import {
  checkOnu,
  onuTypes,
  provisionarOnu,
  desprovisionarOnu,
} from "@/services/api/smartolt.api.js";
import { getCliente } from "@/services/api/clientes.api.js";

export default {
  name: "EventoTarefas",
  props: ["eventoId"],
  components: {
    SelectImage: () => import("@/components/SelectImage"),
  },
  data() {
    return {
      tarefaChecklist: [],
      tarefaText: [],
      tarefaBoolean: [],
      tarefaImagem: [],
      checkListItem: {},
      textItem: {},
      editOffset: -1,
      tarefas: [],
      tarefa: {},
      imagemItem: {},
      dialogTarefa: false,
      loadingTarefa: false,
      tiposTarefa: [
        {
          text: "Text",
          value: "text",
        },
        {
          text: "Checklist",
          value: "checklist",
        },
        {
          text: "Boolean",
          value: "boolean",
        },
        {
          text: "Imagem",
          value: "imagem",
        },
      ],
      loading: false,
      tarefaProvisionamento: null,
      serial: "",
      provisionando: false,
      onuValida: null,
      validandoOnu: false,
    };
  },
  computed: {
    ...mapState("Evento", {
      original: (state) => state.evento,
      eventoProdutos: (state) => state.eventosProdutos,
    }),
    evento_id() {
      return this.$route.params.eventoId;
    },
    disableTarefa() {
      let valid = true;
      if (this.tarefa.descricao && this.tarefa.tipo) {
        valid = false;
      }
      return valid;
    },
  },
  watch: {},
  methods: {
    async verificaOnu() {
      try {
        this.$Progress.start();
        this.validandoOnu = true;

        let dadosProvisionar = {};

        const checkOnusResponse = await checkOnu();
        if (checkOnusResponse.status) {
          let onus = checkOnusResponse.response;

          if (onus.length) {
            const onu = onus.find((item) => item.sn === this.serial);
            if (onu) {
              dadosProvisionar.olt_id = onu.olt_id;
              dadosProvisionar.pon_type = onu.pon_type;
              dadosProvisionar.board = onu.board;
              dadosProvisionar.port = onu.port;
              dadosProvisionar.sn = onu.sn;
              dadosProvisionar.vlan = "100";
              dadosProvisionar.onu_type = onu.onu_type_name;
              dadosProvisionar.zone = "1";
              dadosProvisionar.zone = "1";
              dadosProvisionar.odb = "none";
              dadosProvisionar.name = this.original.cliente;
              dadosProvisionar.address_or_comment = "By Tocs";

              const cliente = await getCliente(this.original.cliente_id);
              dadosProvisionar.onu_external_id = cliente.adm_id;

              const onuTypesResponse = await onuTypes();
              const onu_type = onuTypesResponse.response.find(
                (item) => item.id === onu.onu_type_id
              );

              if (onu_type) {
                dadosProvisionar.onu_mode =
                  onu_type.capability === "Bridging/Routing"
                    ? "Routing"
                    : onu_type.capability;
              }

              this.onuValida = true;
            } else {
              throw "ONU nao encontrada";
            }
          } else {
            throw "Nenhuma ONU pendente";
          }
        } else {
          throw "Erro ao verificar ONU";
        }

        let invalido = false;
        let check = [
          "olt_id",
          "pon_type",
          "board",
          "port",
          "sn",
          "vlan",
          "onu_type",
          "zone",
          "odb",
          "name",
          "address_or_comment",
          "onu_mode",
          "onu_external_id",
        ];
        check.forEach((item) => {
          let validate =
            Object.is(dadosProvisionar[item], null) ||
            Object.is(dadosProvisionar[item], undefined) ||
            Object.is(dadosProvisionar[item], "");
          if (validate) {
            invalido = true;
          }
        });

        if (!invalido) {
          provisionarOnu(dadosProvisionar).then((response) => {
            if (response.data.onu.status) {
              this.tarefaProvisionamento.valor = dadosProvisionar.sn;
              this.updateTarefa(
                this.tarefaProvisionamento.id,
                this.tarefaProvisionamento
              );
              this.$Progress.finish();
              this.validandoOnu = false;
              this.$toast.success("ONU provisionada com sucesso!");
            } else {
              throw "Erro ao provisionar ONU";
            }
          });
        }
      } catch (error) {
        this.$Progress.fail();
        this.validandoOnu = false;
        this.onuValida = false;
        console.error(error);
        this.$toast.error(error);
      }
    },
    async excluirProvisionamento() {
      try {
        this.$Progress.start();
        this.loading = true;

        const cliente = await getCliente(this.original.cliente_id);

        await desprovisionarOnu(cliente.adm_id).then((response) => {
          if (response.status) {
            this.tarefaProvisionamento.valor = null;
            this.updateTarefa(
              this.tarefaProvisionamento.id,
              this.tarefaProvisionamento
            );
            this.loading = false;
            this.$toast.success("Provisionamento removido com sucesso");
            this.$Progress.finish();
          } else {
            throw "Erro ao provisionar ONU";
          }
        });
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
    M_getEventoTarefas() {
      this.loading = true;
      this.tarefaChecklist = [];
      this.tarefaText = [];
      this.tarefaBoolean = [];
      this.tarefaImagem = [];
      getEventoTarefas(`?evento_id=${this.evento_id}`)
        .then((response) => {
          this.tarefas = response;
          response.forEach((item) => {
            if (item.tipo === "checklist") {
              this.tarefaChecklist.push(item);
            }
            if (item.tipo === "text") {
              item.focus = false;
              this.tarefaText.push(item);
            }
            if (item.tipo === "boolean") {
              this.tarefaBoolean.push(item);
            }
            if (item.tipo === "imagem") {
              this.tarefaImagem.push(item);
            }
            if (item.tipo === "provisionamento") {
              this.tarefaProvisionamento = item;
              // if (this.tarefaProvisionamento.valor) {
              //   this.serial = this.tarefaProvisionamento.valor;
              //   this.validaSerial();
              // }
            }
          });
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    updateTarefa(id, tarefa) {
      this.$Progress.start();
      return new Promise((resolve, reject) => {
        putEventoTarefa(id, tarefa)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Tarefa atualizada com sucesso!");
              this.$Progress.finish();
              this.M_getEventoTarefas();
              resolve();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
            reject();
          });
      });
    },
    createTarefa() {
      this.tarefa.evento_id = this.evento_id;
      this.$Progress.start();
      this.loadingTarefa = true;
      postEventoTarefa(this.tarefa)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Tarefa criada com sucesso!");
            this.tarefa = {};
            this.dialogTarefa = false;
            this.M_getEventoTarefas();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => (this.loadingTarefa = false));
    },
    toogleChecklist(tarefa) {
      if (tarefa.valor === null) {
        tarefa.valor = "1";
      } else if (tarefa.valor === "0") {
        tarefa.valor = "1";
      } else if (tarefa.valor === "1") {
        tarefa.valor = "0";
      }
      this.updateTarefa(tarefa.id, tarefa);
    },
    toogleBooleanItem(tarefa) {
      if (tarefa.valor === null) {
        tarefa.valor = "1";
      } else if (tarefa.valor === "0") {
        tarefa.valor = "1";
      } else if (tarefa.valor === "1") {
        tarefa.valor = "0";
      }
      this.updateTarefa(tarefa.id, tarefa);
    },
    editCheckListItem(index) {
      this.editOffset = index;
    },
    updateCheckListItem(index) {
      this.checkListItem = this.tarefaChecklist[index];
      this.updateTarefa(this.checkListItem.id, this.checkListItem).finally(
        () => {
          this.editOffset = -1;
        }
      );
    },
    updateTextItem(index) {
      this.textItem = this.tarefaText[index];
      delete this.textItem.focus;
      this.updateTarefa(this.textItem.id, this.textItem);
    },
  },
  mounted() {
    this.M_getEventoTarefas();
  },
};
</script>

<style lang="scss" scoped></style>
