import api from "../httpClient.js";

// Evento Tipo Tarefas
export async function getAllEventosTarefas(filtros) {
  const response = await api.get(`/eventostipostarefas${filtros}`);
  return response.data.eventostipostarefas;
}

export async function getOneEventosTarefas(id) {
  const response = await api.put(`/eventostipostarefas/${id}`);
  return response.data.eventostipostarefas;
}
export function putEventosTarefas(id, tarefa) {
  let body = {};
  for (let key in tarefa) {
    body[key] = tarefa[key];
  }
  return api.put(`/eventostipostarefas/${id}`, body);
}
export function postEventosTarefas(tarefa) {
  let body = {};
  for (let key in tarefa) {
    body[key] = tarefa[key];
  }
  return api.post("/eventostipostarefas/add", body);
}

// Evento Tarefas
export async function getEventoTarefas(filtros) {
  const response = await api.get(`/eventostarefas${filtros}`);
  return response.data.eventostarefas;
}

export function putEventoTarefa(id, tarefa) {
  let body = {};
  for (let key in tarefa) {
    if (key != "imgs") {
      body[key] = tarefa[key];
    }
  }
  return api.put(`/eventostarefas/${id}`, body);
}

export function postEventoTarefa(tarefa) {
  let body = {};
  for (let key in tarefa) {
    body[key] = tarefa[key];
  }
  return api.post("/eventostarefas/add", body);
}

export async function uploadImage(fd) {
  const response = await api.post("/eventostarefas/uploadImage", fd);
  return response;
}
